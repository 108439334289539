// import Vue from 'vue'
import swal from 'sweetalert2'
import axios from 'axios'
import rpc from '@/auth/rpc/ociRpc'
import store from '@/store'
import router from '@/router'
import { messageBox } from '@/components/ui/MessageBox'
import Configuration from '@/utils/configuration'
import { generalMethods } from '@/components/General-Functions'
import { version } from '../../../package.json'

/* eslint-disable */
export const loginMethods = {
  path: process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/',
  loginUser: '',
  loginPw: '',
  loginID: '',
  loginToken: '',
  userDetails: '',
  wizardID: '',
  sessionAuth: '',
  callback: null,
  device_id: null,

  reset() {
    this.loginUser = '';
    this.loginPw = '';
    this.loginID = '';
    this.loginToken = '';
    this.userDetails = '';
    this.wizardID = '';
    this.sessionAuth = ''; 
    // this.device_id = null;
  },

  handleCatch(error, message) {
    console.log(error);
    messageBox.displayStandard('Error', message, 'error');
    this.reset();
    if (this.callback) this.callback
    return error;
  },
  
  setSessionAuth() {
    const sAuth1 = String('admin:') + String(this.loginID) + String(':') + String(this.loginToken);
    const sAuth = String(btoa(sAuth1));
    this.sessionAuth = String('Session ') + sAuth;
  },
  async Login(userEmail, password, callback) {
    console.log('Login 1.0')
    console.log(version)    
    this.device_id = localStorage.getItem("OCIDEVICEID")
    this.loginUser = userEmail;
    this.loginPw = password;
    this.callback = callback
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
        'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS, post, get',
        'Access-Control-Allow-Credentials': 'true',
      },
      timeout: 120000,
    };
    const payload = {
      // jsonrpc: '1.0',
      id: '1',
      method: 'common.db.web_user_login',
      params: ['TpASZ6-vqVg_rfHb', this.loginUser, this.loginPw, this.device_id]
      // params: ['web_user', this.loginUser, this.loginPw, this.device_id]
    };

    try{
      const response = await axios.post(this.path+'web_login', payload, config);
      if (response.data['result'] !== undefined) {     
        // [user_id, session, web_user_id, token, name, device, data]
        let user_id = response.data.result[0]
        let session = response.data.result[1]
        let web_user_id = response.data.result[2]
        let token = response.data.result[3]
        let name = response.data.result[4]
        let device = response.data.result[5]   
        let data = response.data.result[6]   

        rpc.setUserId(user_id);
        rpc.setToken(session);
        this.device_id = device; //response.data.result.view.defaults.device;
        localStorage.setItem("OCIDEVICEID", this.device_id)
        rpc.setWebToken(token); //response.data.result.view.defaults.token
        store.commit('app/setUserName', name); //response.data.result.view.defaults.name
        store.commit('app/setUserEmail', userEmail);
        console.log('ASSIGNMENTS')
        console.log(response.data.result[4])
        // #LL is not returned here anymore store.commit('app/setUserAssignmentsID', data.assignments); //response.data['result'].view.defaults.assignments
        // store.commit('app/setUserDetails', this.userDetails);
        store.commit('app/setCurrentDateHeading');
        store.commit('app/setLoggedIn');
        //await this.DeleteWebUserTokenWizard();
        const assignmentID = store.getters['app/getUserAssignmentsID'];
        console.log('assignmentID')
        console.log(assignmentID)
        if (assignmentID > 0){
          await store.dispatch('app/getCurrentJobForCurrentDay', assignmentID); //This is here, to check again after checkout, if has more assignments
        }
        store.commit('app/setEthosURL', []);
        console.log('reminder_image_urls: '+ data.reminder_image_urls)
        // const reminder_image_urls = ['https://files.promentum.co.uk/oci/ethos/asbestos.svg', 'https://files.promentum.co.uk/oci/ethos/ethos_ppe.svg', 'https://files.promentum.co.uk/oci/ethos/hoists.svg'] // response.data.result[4].reminder_image_urls
        const reminder_image_urls = data.reminder_image_urls
        const user_party = data.user_party
        console.log('user_party', user_party)
        store.commit('app/setDriverId', user_party);
        store.commit('app/clearWasteMaterials', []);
        store.commit('app/clearWasteLocations', []);
        store.commit('app/clearDCLineTypes', []);

        //get version from package.json file!!!! (increase value for each release)
        console.log('version: '+ version)
        console.log('versions: '+ data.versions)
        console.log('versions-oci-app: '+ data.versions['oci-app'])

        if (data.versions){
          if (data.versions['oci-app'] != version) {
            setTimeout(() => { // use setTimeot or set a variable and reload when it goes to new page
              window.location.reload(true); //window.location.reload();
            }, 3000);            
          }
        }

        if (reminder_image_urls !== undefined){
            const ethos = reminder_image_urls;
            const result = Array.isArray(ethos);
            if(result) {
                const ethos_image_url = ethos;
                if (ethos_image_url != null && ethos_image_url != undefined && ethos_image_url != ''){
                  store.commit('app/setEthosURL', ethos_image_url);
                  router.push('/ethos');                
                }else{
                  router.push('/');               
                }
            } else {
              router.push('/');            
          }                
        }else{
          router.push('/');         
        }        
      } else if (response.data['error'] !== undefined) {
        messageBox.displayErrorFromData(response.data);
        if (this.callback) this.callback()
      } else {
        messageBox.displayStandard('Error', 'Unable to Login at this time [Login failed]', 'error');
        if (this.callback) this.callback()
      }  
    }catch(Exception){
      if (this.callback) this.callback()
      messageBox.displayStandard('Error', Exception, 'error');
      return this.handleCatch(Exception, 'Unable to login');
    }
  },

  async OldLogin(userEmail, password, callback) {
    this.loginUser = userEmail;
    this.loginPw = password;
    this.callback = callback
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
        'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS, post, get',
        'Access-Control-Allow-Credentials': 'true',
      },
      timeout: 120000,
    };
    const payload = {
      // jsonrpc: '1.0',
      id: '1',
      method: 'common.db.login',
      params: ['web_user', { email: this.loginUser, password: this.loginPw }] //This way you Login to proCentral with the web user details, so no need to hardcode anything on VueJS
    };

    try{
      const response = await axios.post(this.path, payload, config);
      if (response.data['result'] !== undefined) {
        const iterator = response.data['result'].values();
        this.loginID = iterator.next().value;
        this.loginToken = iterator.next().value;
        store.commit('app/setIsLoadingAssigments', false);
        this.setSessionAuth();
        //await this.getUser();       
        await this.LoginWebUser();
      } else if (response.data['error'] !== undefined) {
        messageBox.displayErrorFromData(response.data);
        if (this.callback) this.callback()
      } else {
        messageBox.displayStandard('Error', 'Unable to Login at this time [Login failed]', 'error');
        if (this.callback) this.callback()
      }  
    }catch(Exception){
      if (this.callback) this.callback()
      messageBox.displayStandard('Error', Exception, 'error');
      return this.handleCatch(Exception, 'Unable to login');
    }
  },

  async LoginWebUser() {
    console.log('LoginWebUser')
    this.device_id = localStorage.getItem("OCIDEVICEID")
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: this.sessionAuth,
      },
    };
    // const userDetailsParams = this.userDetails;
    const payload = {
      // jsonrpc: '1.0',
      id: '1',
      method: 'model.web.user.login_web_user',
      params: [this.loginUser, this.loginPw, this.device_id, {}]
    };

    try{
      const response = await axios.post(this.path, payload, config);
      if (response) {
        store.commit('app/setIsLoadingAssigments', false);
        if (response.data['result'] !== undefined) {
          rpc.setUserId(this.loginID);
          rpc.setToken(this.loginToken);
          this.device_id = response.data.result[3]; //response.data.result.view.defaults.device;
          localStorage.setItem("OCIDEVICEID", this.device_id)
          rpc.setWebToken(response.data.result[1]); //response.data.result.view.defaults.token
          store.commit('app/setUserName', response.data.result[2]); //response.data.result.view.defaults.name
          console.log('ASSIGNMENTS')
          console.log(response.data.result[4])
          store.commit('app/setUserAssignmentsID', response.data.result[4].assignments); //response.data['result'].view.defaults.assignments
          // store.commit('app/setUserDetails', this.userDetails);
          store.commit('app/setCurrentDateHeading');
          store.commit('app/setLoggedIn');
          //await this.DeleteWebUserTokenWizard();
          const assignmentID = store.getters['app/getUserAssignmentsID'];
          console.log('assignmentID')
          console.log(assignmentID)
          if (assignmentID > 0){
            await store.dispatch('app/getCurrentJobForCurrentDay', assignmentID); //This is here, to check again after checkout, if has more assignments
          }
          store.commit('app/setEthosURL', []);
          console.log('reminder_image_urls: '+ response.data.result[4].reminder_image_urls)
          // const reminder_image_urls = ['https://files.promentum.co.uk/oci/ethos/asbestos.svg', 'https://files.promentum.co.uk/oci/ethos/ethos_ppe.svg', 'https://files.promentum.co.uk/oci/ethos/hoists.svg'] // response.data.result[4].reminder_image_urls
          const reminder_image_urls = response.data.result[4].reminder_image_urls
          if (reminder_image_urls !== undefined){
              const ethos = reminder_image_urls;
              const result = Array.isArray(ethos);
              if(result) {
                  const ethos_image_url = ethos;
                  if (ethos_image_url != null && ethos_image_url != undefined && ethos_image_url != ''){
                    store.commit('app/setEthosURL', ethos_image_url);
                    router.push('/ethos'); 
                  }else{
                    router.push('/');
                  }
              } else {
                router.push('/');
            }                
          }else{
            router.push('/');
          }
          
          //router.push('/ethos');     
        } else if (response.data['error'] !== undefined) {
          messageBox.displayErrorFromData(response.data);
          if (this.callback) this.callback
        } else {
          messageBox.displayStandard('Error', 'Unable to Login at this time [Login execute]', 'error');
          if (this.callback) this.callback
        }
      }  
    }catch(Exception){
      if (this.callback) this.callback()
      messageBox.displayStandard('Error', Exception, 'error');
      return this.handleCatch(Exception, 'Unable to complete login');
    }     
  },

  async getUser() {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: this.sessionAuth,
      },
    };
    const payload = {
      id: '1',
      method: 'model.res.user.get_preferences',
      params: [[], {}],
    };

    try{
      const response = await axios.post(this.path, payload, config);
      if (response) {
        if (response.data['result'] !== undefined) {
          this.userDetails = JSON.stringify(response.data['result']);
          await this.GetWebUserTokenCreate();
        } else if (response.data['error'] !== undefined) {
          messageBox.displayErrorFromData(response.data);
          if (this.callback) this.callback
        } else {
          messageBox.displayStandard('Error', 'Unable to Login at this time [details failed]', 'error');
          if (this.callback) this.callback
        }        
      }  
    }catch(Exception){
      if (this.callback) this.callback()
      messageBox.displayStandard('Error', Exception, 'error');
      return this.handleCatch(Exception, 'Unable to retrieve your details');
    }
    /*
    axios.post(this.path, payload, config)
      .then((response) => {
        if (response.data['result'] !== undefined) {
          this.userDetails = JSON.stringify(response.data['result']);
          this.GetWebUserTokenCreate();
        } else if (response.data['error'] !== undefined) {
          messageBox.displayErrorFromData(response.data);
          if (this.callback) this.callback
        } else {
          messageBox.displayStandard('Error', 'Unable to Login at this time [details failed]', 'error');
          if (this.callback) this.callback
        }
      })
      .catch((error) => {
        return this.handleCatch(error, 'Unable to retrieve your details');
      });
      */
  },

  async GetWebUserTokenCreate() {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: this.sessionAuth,
      },
    };
    const params = this.userDetails
    const payload = {
      id: '1',
      method: 'wizard.web.user.login.create',
      params: [ { params } ],
    };

    try{
      const response = await axios.post(this.path, payload, config);
      if (response) {
        if (response.data['result'] !== undefined) {
          const iterator = response.data['result'].values();
          this.wizardID = iterator.next().value;
          await this.GetWebUserTokenExecute();
        } else if (response.data['error'] !== undefined) {
          messageBox.displayErrorFromData(response.data);
          if (this.callback) this.callback
        } else {
          messageBox.displayStandard('Error', 'Unable to Login at this time [Webtoken create]', 'error');
          if (this.callback) this.callback
        }    
      }  
    }catch(Exception){
      if (this.callback) this.callback()
      messageBox.displayStandard('Error', Exception, 'error');
      return this.handleCatch(Exception, 'Unable to create login token');
    }    
    /*
    axios.post(this.path, payload, config)
      .then((response) => {
        if (response.data['result'] !== undefined) {
          const iterator = response.data['result'].values();
          this.wizardID = iterator.next().value;
          this.GetWebUserTokenExecute();
        } else if (response.data['error'] !== undefined) {
          messageBox.displayErrorFromData(response.data);
          if (this.callback) this.callback
        } else {
          messageBox.displayStandard('Error', 'Unable to Login at this time [Webtoken create]', 'error');
          if (this.callback) this.callback
        }
      })
      .catch((error) => {
        return this.handleCatch(error, 'Unable to create login token');
      });
    */
  },

  async GetWebUserTokenExecute() {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: this.sessionAuth,
      },
    };

    const params = this.userDetails
    const payload = {
      // jsonrpc: '1.0',
      id: '1',
      method: 'wizard.web.user.login.execute',
      params: [this.wizardID, {}, 'start', { params }],
    };
    
    try{
      const response = await axios.post(this.path, payload, config);
      if (response) {
        if (response.data['result'] !== undefined) {
          await this.GetWebUserTokenExecuteLogin();
        } else if (response.data['error'] !== undefined) {
          messageBox.displayErrorFromData(response.data);
          if (this.callback) this.callback
        } else {
          messageBox.displayStandard('Error', 'Unable to Login at this time [Token execute]', 'error');
          if (this.callback) this.callback
        } 
      }  
    }catch(Exception){
      if (this.callback) this.callback()
      messageBox.displayStandard('Error', Exception, 'error');
      return this.handleCatch(Exception, 'Unable to retrieve login token');
    }    
    /*
    axios.post(this.path, payload, config)
      .then((response) => {
       if (response.data['result'] !== undefined) {
          this.GetWebUserTokenExecuteLogin();
        } else if (response.data['error'] !== undefined) {
          messageBox.displayErrorFromData(response.data);
          if (this.callback) this.callback
        } else {
          messageBox.displayStandard('Error', 'Unable to Login at this time [Token execute]', 'error');
          if (this.callback) this.callback
        }
      })
      .catch((error) => {
        return this.handleCatch(error, 'Unable to retrieve login token');
      });
      */
  },

  async GetWebUserTokenExecuteLogin() {
    console.log('GetWebUserTokenExecuteLogin')
    this.device_id = localStorage.getItem("OCIDEVICEID")
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: this.sessionAuth,
      },
    };
    const userDetailsParams = this.userDetails;
    const payload = {
      // jsonrpc: '1.0',
      id: '1',
      method: 'wizard.web.user.login.execute',
      params: [this.wizardID, { start: { email: this.loginUser, password: this.loginPw, device: this.device_id} }, 'login', { userDetailsParams } ],
    };

    try{
      const response = await axios.post(this.path, payload, config);
      if (response) {
        if (response.data['result'] !== undefined) {
          rpc.setUserId(this.loginID);
          rpc.setToken(this.loginToken);
          this.device_id = response.data.result.view.defaults.device;
          localStorage.setItem("OCIDEVICEID", this.device_id)
          rpc.setWebToken(response.data.result.view.defaults.token);
          store.commit('app/setUserName', response.data.result.view.defaults.name);
          store.commit('app/setUserAssignmentsID', response.data['result'].view.defaults.assignments);
          store.commit('app/setUserDetails', this.userDetails);
          store.commit('app/setCurrentDateHeading');
          store.commit('app/setLoggedIn');
          await this.DeleteWebUserTokenWizard();
        } else if (response.data['error'] !== undefined) {
          messageBox.displayErrorFromData(response.data);
          if (this.callback) this.callback
        } else {
          messageBox.displayStandard('Error', 'Unable to Login at this time [Login execute]', 'error');
          if (this.callback) this.callback
        }
      }  
    }catch(Exception){
      if (this.callback) this.callback()
      messageBox.displayStandard('Error', Exception, 'error');
      return this.handleCatch(Exception, 'Unable to login');
    }     
    /*
    axios.post(this.path, payload, config)
      .then((response) => {
        if (response.data['result'] !== undefined) {
          rpc.setUserId(this.loginID);
          rpc.setToken(this.loginToken);
          this.device_id = response.data.result.view.defaults.device;
          localStorage.setItem("OCIDEVICEID", this.device_id)
          rpc.setWebToken(response.data.result.view.defaults.token);
          store.commit('app/setUserName', response.data.result.view.defaults.name);
          store.commit('app/setUserAssignmentsID', response.data['result'].view.defaults.assignments);
          store.commit('app/setUserDetails', this.userDetails);
          store.commit('app/setCurrentDateHeading');
          store.commit('app/setLoggedIn');
          this.DeleteWebUserTokenWizard();
        } else if (response.data['error'] !== undefined) {
          messageBox.displayErrorFromData(response.data);
          if (this.callback) this.callback
        } else {
          messageBox.displayStandard('Error', 'Unable to Login at this time [Login execute]', 'error');
          if (this.callback) this.callback
        }
      })
      .catch((error) => {
        return this.handleCatch(error, 'Unable to login');
      });
      */
  },

  async DeleteWebUserTokenWizard() {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: this.sessionAuth,
      },
    };
    const params = this.userDetails;
    const payload = {
      id: '1',
      method: 'wizard.web.user.login.delete',
      params: [this.wizardID, {params}],
    };

    try{
      const response = await axios.post(this.path, payload, config);
      if (response) {
        store.commit('app/setIsLoadingAssigments', false);
        if (response.data['result'] !== undefined) {
          const assignmentID = store.getters['app/getUserAssignmentsID'];
          if (assignmentID > 0){
            await store.dispatch('app/getCurrentJobForCurrentDay', assignmentID); //This is here, to check again after checkout, if has more assignments
          }
          router.push('/');
          // router.push('/ethos');   #LL put back later
        } else if (response.data['error'] !== undefined) {
          messageBox.displayErrorFromData(response.data);
          if (this.callback) this.callback
        } else {
          messageBox.displayStandard('Error', 'Unable to Login at this time [Wizard failed]', 'error');
          if (this.callback) this.callback
        }
      }  
    }catch(Exception){
      if (this.callback) this.callback()
      messageBox.displayStandard('Error', Exception, 'error');
      return this.handleCatch(Exception, 'Unable to complete login');
    }    
    /*
    axios.post(this.path, payload, config)
      .then((response) => {
        store.commit('app/setIsLoadingAssigments', false);
        if (response.data['result'] !== undefined) {
          const assignmentID = store.getters['app/getUserAssignmentsID'];
          if (assignmentID > 0){
            store.dispatch('app/getCurrentJobForCurrentDay', assignmentID); //This is here, to check again after checkout, if has more assignments
          }
          router.push('/');
        } else if (response.data['error'] !== undefined) {
          messageBox.displayErrorFromData(response.data);
          if (this.callback) this.callback
        } else {
          messageBox.displayStandard('Error', 'Unable to Login at this time [Wizard failed]', 'error');
          if (this.callback) this.callback
        }
      })
      .catch((error) => {
        store.commit('app/setIsLoadingAssigments', false);
        return this.handleCatch(error, 'Unable to complete login');
      });
      */
  },

  ResetPassword(userEmail, callback) {
    this.loginUser = userEmail;
    this.callback = callback
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
        'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS, post, get',
        'Access-Control-Allow-Credentials': 'true',
      },
    };
    const payload = {};
    axios.get(this.path+'reset-password?email='+this.loginUser, payload, config)
      .then(() => {
        if (this.callback) {
          this.callback()
        }  
      })
      .catch(() => {
        if (this.callback) {
          this.callback()
        }
      });
  },

  UpdatePassword(data, callback) {
    this.callback = callback
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(this.path+'update-password', data, config)
      .then((response) => {
        if (this.callback) this.callback(response)
      })
      .catch((error) => {
        return this.handleCatch(error, 'Unable to Reset Password');
      });
  },

  async SaveUser(data, callback) {
    console.log('SaveUser')
    this.callback = callback
    const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
    const sAuth = String(btoa(sAuth1))
    const sessionAuth = String('Session ') + sAuth
    this.webToken = rpc.getWebToken();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
        'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS, post, get',
        'Access-Control-Allow-Credentials': 'true',
        Authorization: sessionAuth,
      },
    };
    const params = this.userDetails;
    const payload = {
      id: '1',
      method: 'model.web.user.change_password',
      params: [data, {web_user_session: this.webToken}],
    };

    try{
      const response = await axios.post(this.path, payload, config);
      if (response) {
        if (response.data['result'] !== undefined) {
          messageBox.displayStandard('Success', 'Password changed', 'success');
          if (this.callback) this.callback
        } else if (response.data['error'] !== undefined) {
          messageBox.displayErrorFromData(response.data);
          if (this.callback) this.callback
        } else {
          messageBox.displayStandard('Error', 'Unable to Login at this time [Wizard failed]', 'error');
          if (this.callback) this.callback
        }
      }  
    }catch(Exception){
      if (this.callback) this.callback()
      messageBox.displayStandard('Error', Exception, 'error');
      return this.handleCatch(Exception, 'Unable to complete login');
    }  
  },  

}
/* eslint-enable */
